<template>
    <div>
      <div class="row">
        <div class="col-12 col-md-3">
          <input-select-search
            ref="anoLetivoId"
            :label="$t(`ACADEMICO.ANO_LETIVO`)"
            :options="opcoes.anosLetivos"
            v-model="filtro.anoLetivoId"
            required
          />
        </div>
        <div class="col-12 col-md-auto align-items-center">
          <b-button variant="primary" class="botao-acao-filtro w-100" @click="filtrar">
            {{ $t('GERAL.FILTRAR') }}
          </b-button>
        </div>
        <div class="col-12 col-md-auto">
          <b-button
            variant="secondary"
            class="botao-acao-filtro mt-3 w-100"
            @click="limparFiltro"
          >
            {{ $t('GERAL.LIMPAR') }}
          </b-button>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { START_LOADING, STOP_LOADING } from '@/store/Store';
  import mensagem from '@/common/utils/mensagem';
  import helpers from '@/common/utils/helpers';
  
  // Services:
  import AnoLetivoService from '@/common/services/anoLetivo/ano-letivo.service';
  
  import { InputSelectSearch } from '@/components/inputs';
  
  export default {
    components: {
      InputSelectSearch,
    },
    data() {
      return {
        filtro: {
          anoLetivoId: '',
        },
        opcoes: {
          anosLetivos: [],
        },
      };
    },
    mounted() {
      this.getAnosLetivo();
      this.$emit('refs', this.$refs);
    },
    methods: {
      getAnosLetivo() {
        this.$store.dispatch(START_LOADING);
        AnoLetivoService.listar(this.paginacao)
          .then(({ data }) => {
            data.forEach((element) => {
              element.text = element.ano;
              element.value = element.id;
            });
            this.opcoes.anosLetivos = data;
          })
          .catch((err) => {
            mensagem.showErrors(err);
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      },
      habilitarCampo(key) {
        this.desabilitar[key] = false;
      },
      // FUNÇÕES FILTRO:
      validarFiltro() {
        return helpers.validarFormulario(this.$refs);
      },
      filtrar() {
        if (!this.validarFiltro()) return;
        this.$emit('filtrar', JSON.parse(JSON.stringify(this.filtro)));
      },
      limparFiltro() {
        this.filtro = {
          anoLetivoId: '',
        };
        this.$emit('limparFiltro', { limpeza: true });
      },
    },
  };
  </script>
  