<template>
  <div class="row">
    <div class="col-12 col-md-3">
      <input-select
        :options="opcoes.sigla"
        v-model="form.sigla"
        :label="$t('ACADEMICO.SIGLA')"
        :placeholder="$t('ACADEMICO.SIGLA')"
      />
    </div>
    <div class="col-12 col-md-9">
      <input-text
        ref="descricao"
        v-model="form.descricao"
        type="text"
        :maxLength="40"
        :label="$t('ACADEMICO.NOME')"
        :placeholder="$t('ACADEMICO.NOME')"
        required
      />
    </div>
    <div class="col-12 col-md-6">
      <input-text
        ref="limiteAvaliacao"
        v-model="form.limiteAvaliacao"
        type="number"
        :label="$t('ACADEMICO.QUANTIDADE_AVALIACOES')"
        :placeholder="$t('ACADEMICO.QUANTIDADE_AVALIACOES')"
      />
    </div>
    <div class="col-12 col-md-6">
      <input-select
        ref="form.anoLetivoId"
        :options="opcoes.anosLetivos"
        v-model="form.anoLetivoId"
        :label="$t('GERAL.ANO_LETIVO')"
        valueField="id"
        textField="ano"
        required
      />
    </div>
  </div>
</template>
<script>
import { InputText, InputSelect } from '@/components/inputs';

import AnoLetivoService from '@/common/services/anoLetivo/ano-letivo.service';
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service';

import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

export default {
  props: {
    form: { type: Object, default: Object },
  },
  components: { InputText, InputSelect },
  data() {
    return {
      opcoes: {
        anosLetivos: [],
        sigla: [],
      },
    };
  },
  mounted() {
    this.$emit('refs', this.$refs);
    this.getAnosLetivo();
    this.getSiglaEnum();
  },

  methods: {
    getAnosLetivo() {
      this.$store.dispatch(START_LOADING);
      AnoLetivoService.listar(this.paginacao)
        .then(({ data }) => {
          this.opcoes.anosLetivos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getSiglaEnum() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('sigla')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.sigla = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
