<template>
  <b-table
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    head-variant="light"
    :fields="fields"
    :items="items"
    stacked="md"
    show-empty
    responsive
    striped
    hover
  >
    <template #cell(acoes)="item">
      <div class="flex">
        <b-dropdown variant="outline-default">
          <b-dropdown-item @click="editar(item.item)">
            {{ $t('GERAL.EDITAR') }}
          </b-dropdown-item>
          <b-dropdown-item @click="excluir(item.item)">
            {{ $t('GERAL.EXCLUIR') }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </template>
  </b-table>
</template>
<script>
export default {
  props: {
    items: { type: Array, default: Array },
  },
  data() {
    return {
      fields: [
        {
          key: 'siglaDescricao',
          label: this.$t('GERAL.SIGLA'),
          sortable: true,
        },
        {
          key: 'descricao',
          label: this.$t('GERAL.NOME'),
          sortable: true,
        },
        {
          key: 'limiteAvaliacao',
          label: 'Quantidade máxima de lançamentos',
          sortable: true,
        },
        {
          key: 'acoes',
          label: this.$t('GERAL.ACOES'),
        },
      ],
    };
  },
  methods: {
    editar(item) {
      this.$emit('editar', 'tipoAvaliacao', item);
    },
    excluir(item) {
      this.$emit('excluir', item);
    },
  },
};
</script>
