<template>
  <div>
    <titulo-tipo-avaliacao @cadastrar="openModal" />

    <filtro @filtrar="filtrar" @limparFiltro="filtrar" />

    <tabela-tipo-avaliacao
      class="mt-3"
      :items="tipoAvaliacoes"
      @editar="openModal"
      @excluir="confirmarExclusao"
    />

    <modal-tipo-avaliacao
      :form="modais.tipoAvaliacaoDados"
      :exibir="modais.tipoAvaliacao"
      @atualizar-tabela="filtrar"
      @fechar="closeModal('tipoAvaliacao')"
    />
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import rotas from '@/common/utils/rotas';

// Services & Models:
import TipoAvaliacaoService from '@/common/services/academico/tipoAvaliacao.service.js';
import TipoAvaliacao from '@/common/models/academico/TipoAvaliacao';

// Components:
import TituloTipoAvaliacao from './components/Titulo';
import TabelaTipoAvaliacao from './components/Tabela';
import ModalTipoAvaliacao from './components/Modal';
import Filtro from './components/Filtro.vue';

export default {
  components: {
    TituloTipoAvaliacao,
    TabelaTipoAvaliacao,
    ModalTipoAvaliacao,
    Filtro,
  },
  data() {
    return {
      filtro: {},
      tipoAvaliacoes: [],
      modais: {
        tipoAvaliacao: false,
        tipoAvaliacaoDados: {},
      },
    };
  },
  methods: {
    // FUNÇÕES PARA OBTENÇÃO DE DADOS:
    filtrar(filtroUtilizado) {
     
      if (filtroUtilizado?.limpeza) {
        this.tipoAvaliacoes = [];
        return;
      }
      this.filtro = filtroUtilizado ?? this.filtro;
      this.$store.dispatch(START_LOADING);
      TipoAvaliacaoService.obterTodosPorAnoLetivo(this.filtro)
        .then(({ data }) => {
          this.tipoAvaliacoes = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES CRUD:
    async confirmarExclusao(item) {
      const respostaExclusao = await mensagem.confirmacao(
        this.$t('ACADEMICO.CONFIRMAR_EXCLUSAO_DE').formatUnicorn({
          item: item.descricao,
        }),
        this.$t('ACADEMICO.AVISO_NAO_PODE_DESFAZER_ACAO')
      );
      if (respostaExclusao.isConfirmed) {
        this.excluir(item.id);
      }
    },
    excluir(itemId) {
      this.$store.dispatch(START_LOADING);
      TipoAvaliacaoService.excluir(itemId)
        .then(({ data }) => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), data);
          this.filtrar(this.filtro);
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES MODAL:
    openModal(key, item) {
      item
        ? (this.modais[`${key}Dados`] = JSON.parse(JSON.stringify(item)))
        : (this.modais[`${key}Dados`] = new TipoAvaliacao({}));
      this.modais[key] = true;
    },
    closeModal() {
      this.modais.tipoAvaliacao = false;
    },
    // FUNÇÕES AUXILIARES:
    irPara(rota, id) {
      rotas.irPara(rota, id);
    },
  },
};
</script>
